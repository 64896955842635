<template>
   <section class="cont atrialState" >
      <Breadcrumb :crumbs="crumbs"/>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="searchBox p-left-10" style="background:#0c7ffd">
            <el-row class="search-item diy-tabs" style="float: left">
               <el-tabs v-model="routerState">
                  <el-tab-pane label="单日" name="dayCalendar"></el-tab-pane>
                  <el-tab-pane label="日历" name="calendar"></el-tab-pane>
                  <!--<el-tab-pane label="渠道" name="channel"></el-tab-pane>-->
               </el-tabs>
            </el-row>
<!--            <el-row class="search-item" style="float: right">
                  <el-input class="width-370"  v-model="search" clearable
                           placeholder="姓名/手机号/订单号/渠道订单/房间号/备注"></el-input>
               <el-button class="m-left-15"><span>批量关房</span></el-button>
               <el-button class="bg-gradient" type="primary" ><span>房价管理</span></el-button>
            </el-row>-->
         </el-row>
         <!-- 主体内容 -->
         <div>
            <router-view name="atrialState" ref="viewPage"></router-view>
         </div>
         <div class="suspendedSolids">
            <div class="suspendedSolids-box">
               <div class="item"  v-for="item in arr" :key="item.icon" @click="trigger(item)">
                  <i :class="item.icon" class="icon"></i>
                  <span style="font-size: 12px;">{{ item.text }}</span>
               </div>
            </div>
         </div>
         <!-- 今日概览 -->
         <el-dialog class="diy-dialog" title="订单列表" :visible.sync="orderVisible" @close="closeDialog">
            <el-tabs slot="title" v-model="activeName" @tab-click="handleSelect">
               <el-tab-pane v-for="(item,key) in surveyList" :key="key" :label="item.label" :name="key">
                  <div slot="label" class="diy-dialog-tab-item">
                     {{item.label}}
                     <p class="tag" v-if="item.value">{{item.value}}</p>
                  </div>
               </el-tab-pane>
            </el-tabs>
            <el-table height="400" style="margin-top:10px;" :data="orderList" border fit :stripe="true" :header-cell-style="{background:'#f0f3fc'}">
               <el-table-column property="realName" label="姓名" width='90'></el-table-column>
               <el-table-column property="mobile" label="手机号" min-width='100'></el-table-column>
               <el-table-column property="channel" label="渠道">
                  <template slot-scope="scope" >
                     {{dictData['order-source'] && dictData['order-source'][scope.row.channel]}}
                  </template>
               </el-table-column>
               <el-table-column property="time" label="入住类型">
                  <template slot-scope="scope" >
                     <!-- {{dictData['hotel-checkIn-state'] && dictData['hotel-checkIn-state'][scope.row.checkInState]}} -->
                     {{ scope.row.checkInType === 'FULL_TIME' ? '全日房' : '钟点房' }}
                  </template>
               </el-table-column>
               <el-table-column label="房型" min-width='140'>
                  <template slot-scope="scope">
                     {{ scope.row.roomTypeName || '未排房' }}
                  </template>
               </el-table-column>
               <el-table-column label="房间号" width='70'>
                  <template slot-scope="scope">
                     {{ scope.row.roomNo || '未排房' }}
                  </template>
               </el-table-column>
               <el-table-column property="startTime" label="入离时间" min-width='160'>
                  <template slot-scope="scope">
                     <div v-if="scope.row.checkInType === 'FULL_TIME'">
                        {{scope.row.startTime | timeFilter}} 至 {{scope.row.endTime | timeFilter}}，共{{scope.row.checkDate}}晚
                     </div>
                     <div v-else>
                        {{scope.row.startTime}} 至 {{scope.row.endTime}}，共{{hour(scope.row)}}小时
                     </div>
                  </template>
               </el-table-column>
               <el-table-column label="金额" align='right'>
                  <template slot-scope="scope">
                     ￥{{ scope.row.roomRate || 0 }}
                  </template>
               </el-table-column>
            </el-table>
             <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-dialog>
         <!-- 操作日志 -->
         <el-dialog title="操作日志" :visible.sync="dialogTableVisible" @close="closeDialog">
            <el-table height="400" :data="gridData" border fit :stripe="true" :header-cell-style="{background:'#f0f3fc'}">
               <el-table-column property="roomTypeName" label="房型名称"></el-table-column>
               <el-table-column property="roomNo" label="房间号" width="100"></el-table-column>
               <el-table-column label="操作内容" width="100">
                  <template slot-scope="scope">
                     {{ scope.row.state | filterState }}
                  </template>
               </el-table-column>
               <el-table-column label="时间" width="150">
                  <template slot-scope="scope">
                     {{ scope.row.roomStateTime || '-' }}
                  </template>
               </el-table-column>
               <el-table-column property="createTime" label="操作时间" width="200"></el-table-column>
               <el-table-column label="备注">
                  <template slot-scope="scope">
                     {{ scope.row.remark || '无' }}
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-dialog>
         <!-- 读卡 -->
         <el-dialog title="门锁读卡" :visible.sync="cardShow" width="550px">
            <el-form class="form-dialog-box" :model="ruleForm" ref="ruleForm">
               <el-form-item label="房号：">
                  <span>{{ ruleForm.roomNo }}</span>
               </el-form-item>
               <el-form-item label="房间类型：">
                  <span>{{ ruleForm.roomType }}</span>
               </el-form-item>
               <el-form-item label="住客姓名：">
                  <span>{{ ruleForm.name }}</span>
               </el-form-item>
               <el-form-item label="开房类型：">
                  <span>{{ ruleForm.type === 'FULL_TIME' ? '全日房' : ruleForm.type === 'HOUR_ROOM' ? '钟点房' : '' }}</span>
               </el-form-item>
               <el-form-item label="入住时间：">
                  <span>{{ ruleForm.startTime }}</span>
               </el-form-item>
               <el-form-item label="离店时间：">
                  <span>{{ ruleForm.endTime }}</span>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button type="primary" @click="readCard">读卡</el-button>
               <el-button type="primary" @click="returnCard" :disabled="!ruleForm.roomNo">退卡</el-button>
            </span>
         </el-dialog>
      </el-row>
      <!-- 创建用户信息 -->
      <component :is='componentsList.HandleDialog' :dialogTitle="`创建`" :isShow="isShow" @handleClose="handleClose"></component>
      <!-- 交接班 -->
      <el-dialog title="交接班" :visible.sync="visibleDetail" width="1200px"
      :close-on-click-modal="false" class="cont">
         <div class="detail-pop-title">
            <h6>{{ hotelInfo.hotelName }}</h6>
         </div>
         <el-descriptions class="m-top-10 m-bottom-10" :column="3">
            <el-descriptions-item label="班次时间">{{ shiftStartTime }}至{{ shiftEndTime }}</el-descriptions-item>
            <el-descriptions-item label="交班人">{{ userName }}</el-descriptions-item>
            <el-descriptions-item label="选择班次">
            <el-select v-model="shift" size="mini">
               <el-option v-for="(val, key) in dictData['handover-shift']" :key="key" :label="val" :value="key"></el-option>
            </el-select>
            </el-descriptions-item>
         </el-descriptions>
         <el-row type="flex">
            <el-table :data="[{ itemName: '营收合计', amount: shiftStatistics.totalRevenue }]" border>
            <el-table-column label="结算合计" header-align='center'>
               <el-table-column prop="itemName" label="项目"></el-table-column>
               <el-table-column prop="amount" label="金额（元）">
               <template slot-scope="scope">
                  <span>￥{{ scope.row.amount }}</span>
               </template>
               </el-table-column>
            </el-table-column>
            </el-table>
            <el-table :data="[{ itemName: '上班收款', amount: shiftStatistics.previousReceived },
                  { itemName: '本班收款', amount: shiftStatistics.actuallyReceived },
                  { itemName: '本班欠款', amount: shiftStatistics.actuallyArrears }]">
            <el-table-column label="交接钱款" header-align='center'>
               <el-table-column prop="itemName" label="项目"></el-table-column>
               <el-table-column prop="amount" label="金额（元）">
               <template slot-scope="scope">
                  <span>￥{{ scope.row.amount }}</span>
               </template>
               </el-table-column>
            </el-table-column>
            </el-table>
            <el-table :data="[{ itemName: '预定数', amount: shiftStatistics.predestineCount },
                              { itemName: '退房数', amount: shiftStatistics.checkoutCount },
                              { itemName: '入住数', amount: shiftStatistics.checkinCount },
                              { itemName: '打扫数', amount: shiftStatistics.cleanCount },
                              { itemName: '确认数', amount: shiftStatistics.confirmCount }]">
            <el-table-column label="当班操作" header-align='center'>
               <el-table-column prop="itemName" label="项目"></el-table-column>
               <el-table-column prop="amount" label="数量（间）"></el-table-column>
            </el-table-column>
            </el-table>
            <el-table :data="[{ itemName: '已发出房卡', amount: shiftStatistics.sendRoomCount }]">
            <el-table-column label="房卡合计" header-align='center'>
               <el-table-column prop="itemName" label="项目"></el-table-column>
               <el-table-column prop="amount" label="数量（间）"></el-table-column>
            </el-table-column>
            </el-table>
            <el-table :data="consumptionItems">
            <el-table-column label="商品销售" header-align='center'>
               <el-table-column prop="consumptionItemName" label="商品"></el-table-column>
               <el-table-column prop="quantity" label="数量"></el-table-column>
            </el-table-column>
            </el-table>
         </el-row>
         <span slot="footer">
            <el-button @click="visibleDetail = false">取消</el-button>
            <el-button @click="handleShift" type="primary">交班</el-button>
         </span>
      </el-dialog>
   </section>
</template>

<script>
import { getStatisticsState, getPmsOrderList,getRoomStateLog,
  getShiftInfo, saveShiftRecord } from '@/api/pms/orderManage/orderManage';
import { dateFactory } from '@/common/js/common';
import HandleDialog from "../../../components/local/userList/HandleDialog";
import { mapState } from 'vuex'
import getDict from "@/utils/dataDict";
import {urlObj} from "@/api/interface";
import { getNowFormatDateTime } from '@/utils/tools'
import { getByBrandCode , getRoomInfo } from '@/api/pms/atrialState/atrialState'
import axios from "axios";
export default {
   name: "index",
   components:{ HandleDialog },
   data(){
     return{
      componentsList:{
         HandleDialog:''
      },
      crumbs: new Map([
         ['PMS'], ['房态中心']
      ]),
      startTime:"",               // 起始时间
      routerState:'dayCalendar',  // 跳转
      search:'',                  // 查询
      arr:[                       // 悬浮图例数据
         { text:"今日概览",icon:"el-icon-date" },
         { text:"录入订单",icon:"el-icon-document-add" },
         { text:"操作日志",icon:"el-icon-document-copy" },
         { text:"房情表",icon:"el-icon-s-grid",path:"/calendar_report" },
         { text:"添加用户",icon:"el-icon-user" },
         { text:"交接班",icon:"iconfont iconjiaojiebanguanli2" },
         { text:"读卡",icon:"el-icon-takeaway-box" },
      ],
      /** 操作日志 */
      gridData: [],
      dialogTableVisible: false,

      /** 读卡 */
      cardShow: false,
      ruleForm:{
         roomNo: '',
         roomType: '',
         name: '',
         type: '',
         startTime: '',
         endTime: '',
      },
      cardCode:{
         brandCode : '',
         listeningAddress : '',
         listeningPort : ''
      },

      /** 今日概览 */
      orderVisible:false,
      activeName:"checkinCount",
      surveyList:{
         checkinCount:{ // 今日预抵
            label:"今日预抵",
            value:0
         },
         checkoutCount:{ // 今日预离
            label:"今日预离",
            value:0
         },
         liveCount:{ // 已入住
            label:"已入住",
            value:0
         },
         notRoomCount:{ // 未排房
            label:"未排房",
            value:0
         }
      }, // 今日概况的状态列表
      orderList:[],

      total: 0,                // 用户列表总条目数
      page: 1,                 // 当前页 默认第一页
      limit:10,                // 每页显示数
      // dictData:"",

      isShow:false,
       userName: '',
       visibleDetail: false,
       shift: 'MORNING',
       shiftStartTime: '',
       shiftEndTime: '',
       shiftStatistics: {
         totalRevenue: 0,
         previousReceived: 0,
         actuallyReceived: 0,
         actuallyArrears: 0,
         predestineCount: 0,
         checkoutCount: 0,
         cleanCount: 0,
         checkinCount: 0,
         confirmCount: 0,
         sendRoomCount: 0
       },
       consumptionItems: []
     }
   },
   computed:{
      ...mapState(['hotelInfo','dictData']),
      hour(){
         return (item)=>{
            let num = (dateFactory.changeTimestamp(item.endTime) - dateFactory.changeTimestamp(item.startTime))/3600
            return num
         }
      }
   },
   watch: {
      routerState: {
         handler(newVal) {
            this.$router.push({path: `/atrial_state/${newVal}`})
         },
         deep: true
      },
      visibleDetail(visible){
         // 交接班的dialog打开时才取时间
         if(visible) {
            this.shiftStartTime = sessionStorage.getItem('loginTime')
            this.shiftEndTime = getNowFormatDateTime()
         }
      },
      $route:{
         handler(val){
            this.routerState = val.path.split("/")[val.path.split("/").length -1];
         },
         immediate:true
      }
   },
   async mounted(){
      // 将callbackFun方法绑定到window对象
      window['jQueryReadCard'] = (data)=>{
         this.jQueryReadCardCallback(data)
      }
      window['jQueryReturnCard'] = (data)=>{
         this.jQueryReturnCardCallback(data)
      }
     this.userName = JSON.parse(sessionStorage.getItem('userInfo') || '{}').userName
   //   this.shiftStartTime = sessionStorage.getItem('loginTime')
   //   this.shiftEndTime = dateFactory.dateFormat(true)
      getDict(['certificate-type','handover-shift',"order-source","certificate-type","hotel-checkIn-state"]).then( res => {
         this.componentsList.HandleDialog = 'handleDialog'
      })
      this.startTime = dateFactory.getDistanceToday(0,false)
      this.getStatisticsState()
   },
   methods:{
     getShiftData() {
       const params = {
         hotelId: this.hotelInfo.id,
         shiftStartTime: sessionStorage.getItem('loginTime'),
         shiftEndTime: getNowFormatDateTime()
       }
       getShiftInfo(params).then(({ success, data }) => {
         if (success) {
           for (const k in this.shiftStatistics) {
             k in data.shift && (this.shiftStatistics[k] = data.shift[k])
           }
           this.consumptionItems = data.consumptionItems
         }
       })
     },
     handleShift() {
       const params = {
         hotelId: this.hotelInfo.id,
         companyId: this.hotelInfo.storeId,
         shift: this.shift,
         shiftStartTime: this.shiftStartTime,
         shiftEndTime: this.shiftEndTime,
         ...this.shiftStatistics
       }
       saveShiftRecord(params).then(({ success }) => {
         if (success) {
           this.$message({ message: '保存交班成功', type: 'success' })
           setTimeout(() => this.handleLogout(), 2000)
         }
       })
     },
     // 退出登录
     handleLogout(){
       const url = urlObj.logout
       this.$axios.post(url, {}).then(res => {
         if (res.success) {
           this.$router.push('/login');
           sessionStorage.setItem('router', '/login')
         }
       }).catch(error => {
         let response = error.response;
         if(response && response.data && response.data.message){
           let message = response.data.message;
           message = message.split(':');
           if(message.length > 1){
             // 您没有携带有效Token，无法访问后台服务！
             console.log(message[1]);
             return;
           }
         }
         this.$router.push('/login');
         sessionStorage.setItem('router', '/login')
       })
     },
      handleSelect(e){
         this.activeName = e.name
         this.getPmsOrderList()
      },
      getStatisticsState(){
         let data = {
            startTime:this.startTime + " 00:00:00",
            endTime:this.startTime + " 23:59:59",
            hotelId:this.$refs.viewPage.hotelInfo.id
         }
         getStatisticsState(data).then( res => {
            for(let key in this.surveyList){
               this.surveyList[key].value = res.records[key]
            }
         })
      },
      getPmsOrderList(){ // 获取今日订单
         let startKey, endKey, data = {
            companyId:this.$refs.viewPage.hotelInfo.storeId,
            hotelId:this.$refs.viewPage.hotelInfo.id,
            page:this.page,
            limit:this.limit,
         };
         if(this.activeName === 'checkinCount'){ // 今日预抵
            startKey = 'startCheckInTime'
            endKey = 'endCheckInTime'
         }
         if(this.activeName === 'checkoutCount'){ // 今日预离
            startKey = 'startCheckOutTime'
            endKey = 'endCheckOutTime'
            data.checkInState = 'CHECKIN'
         }
         if(this.activeName === 'liveCount'){ // 当前在住
            data.checkInState = 'CHECKIN'
         }
         if(this.activeName === 'notRoomCount'){
            data.boolSettleRoom = true
         }
         startKey && (data[startKey] = this.startTime)
         endKey && (data[endKey] = this.startTime)
         getPmsOrderList(data).then( res =>{
            this.total = res.total
            let mapList = [];
            res.records.forEach( item => {
               item.checkinRoomDTOList.forEach((items,index) => {
                  mapList.push({
                     checkDate:dateFactory.getDistanceDays(items.startTime,items.endTime),
                     ...items
                  })
               })
            })
            this.orderList = mapList
         })
      },
      async trigger(item){
         if (item.text === "操作日志") {
            this.dialogTableVisible = true;
            this.getData();
         } else if (item.text === "房情表"){
            this.$router.push({path:item.path})
         } else if (item.text === "录入订单"){
            this.$refs.viewPage.drawerBool = true;
         } else if (item.text === "今日概览"){
            this.orderVisible = true;
            this.getPmsOrderList();
         } else if (item.text === "添加用户"){
            this.isShow = true
         } else if (item.text === '交接班') {
            this.visibleDetail = true
            this.getShiftData()
         } else if (item.text === '读卡'){
            this.cardShow = true;
            // 获取硬件品牌
            const params = { hotelId: this.hotelInfo.id }
            await getByBrandCode(params).then(({success,data}) => {
               if (success) {
                  this.cardCode.brandCode = data[0].brandCode
                  this.cardCode.listeningAddress = data[0].listeningAddress
                  this.cardCode.listeningPort = data[0].listeningPort
               }
            })
            if (this.cardCode.brandCode == 'ela') {
               const url = this.cardCode.listeningAddress + ':' + this.cardCode.listeningPort + '/lock/readCard/ela';
               axios.post(url).then(({ data }) => {
                  if (!data.isSuccess) {
                     this.$message.error('未读取到卡，请确认卡片是否放入')
                  }
               })
            }
         }
      },
      // 获取房态日志
      getData(){
         let data={
            hotelId:this.$refs.viewPage.hotelInfo.id,
            page:this.page,
            limit:this.limit,
         }
         getRoomStateLog(data).then( res =>{
            this.total = res.total;
            this.gridData = res.records;
         })
      },
      closeDialog(){ // 关闭弹窗时触发
         this.page = 1;
         this.limit = 10;
      },

      handleClose(bool) {
         this.isShow = bool
      },

      // 读卡
      readCard (){
         if (this.cardCode.brandCode == 'ela') {
            const url = this.cardCode.listeningAddress + ':' + this.cardCode.listeningPort + '/lock/readCard/ela';
            axios.post(url).then(({ data }) => {
               if (data.isSuccess) {
                  if (data.data.roomNo) {
                     this.ruleForm.roomNo = data.data.roomNo
                     this.ruleForm.name = data.data.realName
                     this.ruleForm.startTime = data.data.startTime
                     this.ruleForm.endTime = data.data.endTime
                     getRoomInfo({ roomNo : data.data.roomNo }).then(({success,records})=>{
                        if (success) {
                           this.ruleForm.type = records.checkInType
                           this.ruleForm.roomType = records.roomTypeName
                        }
                     })
                  } else {
                     this.$message.error('未查到该卡片有房号信息，请确认')
                  }
               } else {
                  this.$message.error('未读取到卡，请确认卡片是否放入')
               }
            })
            // 兰欧
         } else if (this.cardCode.brandCode == 'lo') {
            const url = this.cardCode.listeningAddress + ':' + this.cardCode.listeningPort + '/local/lock/operation',
            params = {
               callback:'jQueryReadCard',
               sector:0,
               lock_type:7,
               max_lift_addr:1,
               lost:0,
               com:2,
               eare:8,
               dric:11,
               pub_door:2,
               card_type:1,
               lift_data:2,
               is_share:0,
               read_type:13,
               card_name:11,
               log_type:"request",
               _:new Date().getTime(),
               callbackQuery: 'callbackParam',
               callbackName: 'jsonpCallback',
            }
            this.$jsonp(url, params).then(res => {
               // 此处代码不执行
               // 因为会直接调用返回的那个回调函数
            })
         }
      },
      // 兰欧读卡回调
      jQueryReadCardCallback(){
         console.log('兰欧读卡');
      },
      // 退卡
      returnCard (){
         this.$confirm('确定退卡吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => { 
            if (this.cardCode.brandCode == 'ela') {
               const url = this.cardCode.listeningAddress + ':' + this.cardCode.listeningPort + '/lock/resetCard/ela';
               axios.post(url).then(({ data }) => {
                  let message, type;
                  if (data.isSuccess) {
                     if (data.data) {
                        message = '退卡成功！'
                        type = 'success'
                        this.cardShow = false
                        for (const k in this.ruleForm) {
                           this.ruleForm[k] = ''
                        }
                     } else {
                        message = '退卡失败!';
                        type = 'error'
                     }
                  }
                  this.$message({ message, type })
               }).catch(err => {
                  const message = err.response && err.response. data && err.response.data.message
                  this.$message.error(message)
               })
               // 兰欧
            } else if (this.cardCode.brandCode == 'lo') {
               const url = this.cardCode.listeningAddress + ':' + this.cardCode.listeningPort + '/local/lock/operation',
               params = {
                  callback:'jQueryReturnCard',
                  sector:0,
                  lock_type:7,
                  max_lift_addr:1,
                  lost:0,
                  com:2,
                  dric:11,
                  pub_door:2,
                  card_type:1,
                  lift_data:2,
                  is_share:0,
                  read_type:13,
                  card_name:4,
                  sub_door:0,
                  log_type:'request',
                  _:new Date().getTime(),
                  callbackQuery: 'callbackParam',
                  callbackName: 'jsonpCallback',
               }
               let arr = ["", "00000", "0000", "000", "00", "0", ""];
               if (this.ruleForm.roomNo.length <= 6) {
                  params.door = arr[this.ruleForm.roomNo.length] + this.ruleForm.roomNo
               }
               this.$jsonp(url, params).then(res => {
                  // 此处代码不执行
                  // 因为会直接调用返回的那个回调函数
               })
            }
         })
      },
      // 兰欧退卡回调
      jQueryReturnCardCallback(data){
         let message, err
         if (data.ret_code === 0){
            message = '退卡成功！'
            err = 'success'
            this.cardShow = false
            for (const k in this.ruleForm) {
               this.ruleForm[k] = ''
            }
         } else {
            message = '退卡失败！';
            err = 'error'
         }
         this.$message({ message, err })
      },

      // 改变每页数
      pageChange(num) {
         this.limit = num;
         if(this.orderVisible){ // 订单的显示
            this.getPmsOrderList()
         }
         this.getData();
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num;
         if(this.orderVisible){ // 订单的显示
            this.getPmsOrderList()
         }
         this.getData();
      },
   },
   filters:{
      timeFilter(val){
         let time = val.split(" ")[0]
         return time.split("-")[1] + "-" + time.split("-")[2]
      },
      filterState(val){
         switch (val){
            case 'VACANT_ROOM' :
               return '空房'
            case 'LIVE_IN' :
               return '入住'
            case 'CHECK_OUT' :
               return '退房'
            case 'RESERVE' :
               return '预定'
            case 'RETAIN' :
               return '关房'
            case 'NOT_CLEAN_ROOM' :
               return '脏房'
            case 'CLEAN_ROOM' :
               return '干净房'
            case 'REPAIR_ROOM' :
               return '维修房'
            case 'OPEN_ROOM' :
               return '开房'
            default :
               return '-'
         }
      }
   }
}
</script>

<style scoped lang="scss">
.cont{
   .detail-pop-title {
      text-align: center; margin: 0 0 10px 0;
      h6 { font-size: 20px }
   }
   /deep/ .el-dialog__body{
      padding-top: 0;
   }
}
.content-box{
   padding:0px;
}
.atrialState {
   a { text-decoration: none; }
   .router-link-active { text-decoration: none; }
}
.diy-tabs /deep/ .el-tabs__item.is-active{
   color:#f0c54b;
   font-size:17px;
}
.diy-tabs /deep/ .el-tabs__active-bar{
   background:#f0c54b;
}
.diy-tabs /deep/ .el-tabs__item{
   color:#FFF;
}
.diy-tabs /deep/ .el-tabs__header{
   margin-bottom:1px;
}
.suspendedSolids{
   position: fixed;
   left: calc(50vw - 88px);
   bottom: 80px;
   background: rgba(0,0,0,.7);
   border-radius: 90px;
   z-index: 10;
   &-box{
      padding:0 30px;
      color: #fff;
      display: flex;
      .item{
         margin-right: 16px;
         display: flex;
         flex-direction: column;
         align-items: center;
         padding: 5px 0;
         cursor: pointer;
         .icon{
            font-size: 22px;
         }
         &:last-child{
            margin-right: 0;
         }
      }
   }
}
.tag{
   display:inline-block;
   padding:0 2px;
   min-width:14px;
   text-align: center;
   height:16px;
   font-size:12px;
   color:#FFF;
   background:#409eff;
   line-height: 16px;
   margin-left:4px;
}
 .diy-dialog /deep/ .el-dialog .el-dialog__body{
   padding:0 20px 60px !important;
}
.diy-dialog /deep/ .el-dialog .el-dialog__header{
   border-bottom:0px;
   padding:20px 0 0;
   margin-bottom:0;
   .el-tabs .el-tabs__nav-wrap .el-tabs__nav-scroll {
      .el-tabs__active-bar{
         height:3px;
      }
     box-shadow: 0px -1px 1px #e9eaed inset;
      .el-tabs__item{
         width:120px;
         border-bottom:3px solid #e9eaed;
      }
   }
}
.diy-dialog .diy-dialog-tab-item{
   display:flex;
   align-items:center;
   justify-content: center;
}
/deep/ .el-descriptions-item__container {
   align-items: center;
}
</style>

